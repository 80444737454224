/* .chartsDiv {
    margin: auto;
    margin-top: 40px;
    width: 60%;
    padding: 10px;
}
.chartsTable {
    margin-bottom: 20px;
}

.chartCardDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.crashedPage {
    margin: 150px;
    padding: 10%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.crashedPageDiv {
   margin: 150px;
}


.averageTyrePrice {
   width: 20%;
   min-width: 190px;
   max-width: 220px;
}

.headerDivCharts {
    text-align: center;
    font-family: "Montserrat";
    border-bottom: thick double;
    margin-top: 100px;
    margin-bottom: 100px;
}

.bestMatch {
    
    display: flex;
    justify-content: center;
    text-align: center;
}

.eleTwo {
    margin-left: 80px;
} */













/* .chartsDiv {
    margin: auto;
    margin-top: 40px;
    width: 90%;
    max-width: 1000px;
    padding: 10px;
}

.chartsTable {
    margin-bottom: 20px;
}

.chartCardDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.crashedPage {
    margin: 10% 5%;
    padding: 10%;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.crashedPageDiv {
   margin: 10% 5%;
}

.averageTyrePrice {
   width: 100%;
   max-width: 220px;
}

.headerDivCharts {
    text-align: center;
    font-family: "Montserrat";
    border-bottom: thick double;
    margin-top: 5%;
    margin-bottom: 5%;
}

.bestMatch {
    display: flex;
    justify-content: center;
    text-align: center;
}

.eleTwo {
    margin-left: 5%;
}


/* @media only screen and (min-width: 600px) {
    .chartsDiv {
        width: 80%;
    }
}

@media only screen and (min-width: 900px) {
    .chartsDiv {
        width: 70%;
    }
}

@media only screen and (min-width: 1200px) {
    .chartsDiv {
        width: 60%;
    }
}

@media only screen and (min-width: 1600px) {
    .chartsDiv {
        width: 50%;
    }
}  */



.chartsDiv {
    margin: auto;
    margin-top: 3%;
    width: 90%;
    max-width: 1000px;
    padding: 1em;
}

.chartsTable {
    margin-bottom: 1em;
}

.chartCardDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
}

.crashedPage {
    margin: 10% 5%;
    padding: 5%;
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
}

.crashedPageDiv {
   margin: 10% 5%;
}

.averageTyrePrice {
   width: 100%;
   max-width: 20em;
}

.headerDivCharts {
    text-align: center;
    font-family: "Montserrat";
    border-bottom: thick double;
    margin-top: 5%;
    margin-bottom: 5%;
}

.bestMatch {
    display: flex;
    justify-content: center;
    text-align: center;
}

.eleTwo {
    margin-left: 5%;
}

/* Media Queries for Responsive Design */

/* Small screens */
@media (max-width: 576px) {
    .chartsDiv {
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 768px) {
    .chartsDiv {
        width: 90%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

/* Large screens */
@media (min-width: 769px) and (max-width: 992px) {
    .chartsDiv {
        width: 80%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}

/* Extra large screens */
@media (min-width: 993px) {
    .chartsDiv {
        width: 70%;
        margin-top: 5%;
        margin-bottom: 5%;
    }
}
