.tyreStatusMainDiv { 
    margin: 80px auto;
    width: 40%;
    padding: 10px;
}


.popup {
    position:relative;
    margin: 50px auto;
    padding-top: 40px;
    padding-left: 65px;
    height: 360px;
    width: 650px;
}

.frontLeft{
    position: absolute;
	top: 0;
	left: 0;
}
.frontRight{
    position: absolute;
	top: 0;
	right: 0;
}
.rearLeft{
    position: absolute;
	bottom: 0;
	left: 0;
}
.rearRight{
    position: absolute;
	bottom: 0;
	right: 0;
}

.drawnCar {
    margin: 40px;
	padding-top: 40px;
    padding-left: 40px;
}



.chartsTable{
    margin-top: 40px;
}

#imageBanner {
    margin-top: 100px;
    margin-right: 20px;
}

.savingsP {
    border-bottom: thick double;
    /* border-radius: 6px; */
    font-family: "Montserrat";
    /* font-weight: "bold"; */
    padding: 10px;
    /* font-size: 18px; */
    text-align: center;
}

.tyreInfoP {
    /* border: 2px solid #0000ff; */
    
    /* border-radius: 5px; */
    padding: 5px;
    /* color: rgb(2, 2, 138); */
    position: relative;
	top: 0;
	right: 0;
}   