/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 11, 2017 */



@font-face {
    font-family: 'uknumberplateregular';
    src: url('uknumberplate-webfont.woff2') format('woff2'),
         url('uknumberplate-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}