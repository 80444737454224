.headerUsername {
    display: flex;
    padding: '10px';
    margin: 10px;
    border-radius: '5px';
    text-align: 'center';
    font-size: '18px';
    font-weight: 'bold';
    align-items: 'center';
    color: rgb(255, 255, 255);
  }