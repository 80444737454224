/* .tyreDataMainDiv{
    margin: 80px auto;
    width: 40%;
    padding: 10px;
}
.manualInputDiv{
    
    display: inline-flex;
}

.manualInputOptions{
    margin: 15px;
    width: 30%;
}

.differentVehicle {
     cursor:pointer;
     color:blue;
     text-decoration:underline;
}

.differentVehicle:hover{
     text-decoration:none;
     text-shadow: 1px 1px 1px #555;
}
 */









 
/* 
 .tyreDataMainDiv {
    margin: 5% auto;
    width: 80%;
    max-width: 600px;
    padding: 1em;
}

.manualInputDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.manualInputOptions {
    margin: 1em;
    width: 45%;
}

.differentVehicle {
     cursor: pointer;
     color: blue;
     text-decoration: underline;
}

.differentVehicle:hover {
     text-decoration: none;
     text-shadow: 1px 1px 1px #555;
}


@media (max-width: 576px) {
    .tyreDataMainDiv {
        width: 95%;
        margin: 10% auto;
    }
    
    .manualInputOptions {
        width: 100%;
        margin: 1em 0;
    }
}


@media (min-width: 577px) and (max-width: 768px) {
    .tyreDataMainDiv {
        width: 90%;
    }
    
    .manualInputOptions {
        width: calc(50% - 1em);
        margin: 1em 0;
    }
}


@media (min-width: 769px) and (max-width: 992px) {
    .tyreDataMainDiv {
        width: 80%;
        margin: 7% auto;
    }
    
    .manualInputOptions {
        width: 40%;
        margin: 1em;
    }
}


@media (min-width: 993px) {
    .tyreDataMainDiv {
        width: 70%;
        margin: 5% auto;
    }
    
    .manualInputOptions {
        width: 45%;
        margin: 1em;
    }
} */





/* 

.tyreDataMainDiv {
    margin: 5% auto;
    max-width: 600px;
    padding: 1em;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .manualInputDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
  }
  
  .manualInputOptions {
    width: 48%;
    margin-bottom: 1em;
  }
  
  .manualInputOptions label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5em;
  }
  
  .manualInputOptions input[type="text"],
  .manualInputOptions select {
    width: 100%;
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .manualInputOptions select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 1.5em auto, 100%;
  }
  
  .manualInputOptions select::-ms-expand {
    display: none;
  }
  
  .manualInputOptions select:focus {
    border-color: #1e90ff;
    outline: none;
  }
  
  .manualInputOptions input[type="submit"] {
    background-color: #1e90ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5em 1em;
    cursor: pointer;
  }
  
  .manualInputOptions input[type="submit"]:hover {
    background-color: #0066cc;
  }
  
  .differentVehicle {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  
  .differentVehicle:hover {
    text-decoration: none;
    text-shadow: 1px 1px 1px #555;
  }   

  @media (max-width: 576px) {
    .manualInputOptions {
      width: 100%;
    }
  }
  

  @media (min-width: 577px) and (max-width: 768px) {
    .manualInputOptions {
      width: 48%;
    }
  }
  

  @media (min-width: 769px) and (max-width: 992px) {
    .manualInputOptions {
      width: 48%;
    }
  }

  @media (min-width: 993px) {
    .manualInputOptions {
      width: 48%;
    }
  } */
 


  .tyreDataMainDiv {
    margin: 5% auto;
    width: 80%;
    max-width: 600px;
    padding: 1em;
  }
  
  .manualInputDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  
  .manualInputOptions {
    margin: 1em;
    width: 45%;
  }
  
  .differentVehicle {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  
  .differentVehicle:hover {
    text-decoration: none;
    text-shadow: 1px 1px 1px #555;
  }
  
  @media (max-width: 576px) {
    .tyreDataMainDiv {
      width: 95%;
      margin: 10% auto;
    }
    
    .manualInputOptions {
      width: 100%;
      margin: 1em 0;
    }
  }
  
  @media (min-width: 577px) and (max-width: 768px) {
    .tyreDataMainDiv {
      width: 90%;
    }
    
    .manualInputOptions {
      width: calc(50% - 1em);
      margin: 1em 0;
    }
  }
  
  @media (min-width: 769px) and (max-width: 992px) {
    .tyreDataMainDiv {
      width: 80%;
      margin: 7% auto;
    }
    
    .manualInputOptions {
      width: 40%;
      margin: 1em;
    }
  }
  
  @media (min-width: 993px) {
    .tyreDataMainDiv {
      width: 70%;
      margin: 5% auto;
    }
    
    .manualInputOptions {
      width: 45%;
      margin: 1em;
    }
  }
  