

/* 

.searchEngine {
  background-color: #fff;
  margin: 20% auto;
  padding: 25px;
  border: 1px solid #141414;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  min-height: 180px;
}


.searchBar {
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #555;
}


.mainDashboardWrap {

  margin: 80px auto;
  padding: 5px;
  max-width: 1000px;
}


.loadingPage {
  margin: 20% auto;
  width: 60%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.plateInput {
  width: 100%;
  max-width: 350px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #555;
  text-transform: uppercase;
}

.alertP {
  color: #f00;
  margin-top: 10px;
}

.historySelector {
  margin-top: 20px;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  display: flex;
  justify-content: space-between;
}



@media only screen and (max-width: 768px) {
  
  .searchEngine {
    margin: 10% auto;
    max-width: 90%;
  }
  
  .mainDashboardWrap {
    margin: 40px auto;
    max-width: 90%;
  }
  
  .loadingPage {
    margin: 20% auto;
    max-width: 90%;
  }

  .searchBar {
    min-width: 100%;
    max-width: 100%;
  }

  .historySelector {
    min-width: 100%;
    max-width: 100%;
  }
}


@media only screen and (min-width: 769px) {
  
  .searchEngine {
    margin: 20% auto;
    max-width: 600px;
  }
  
  .mainDashboardWrap {
    margin: 80px auto;
    max-width: 1000px;
  }
  
  .loadingPage {
    margin: 20% auto;
    max-width: 600px;
  }

  .searchBar {
    min-width: 300px;
    max-width: 350px;
  }

  .historySelector {
    min-width: 300px;
    max-width: 350px;
  }
} */


/* Search Engine Styles */

/* .searchEngine {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  width: 100%;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.plateInput {
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #555;
  font-size: 16px;
  max-width: 350px;
  padding: 10px;
  text-transform: uppercase;
  width: 100%;
}

.alertP {
  color: #f00;
}


.historySelector {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  max-width: 350px;
  min-width: 300px;
  width: 100%;
}



.mainDashboardWrap {
  margin: 80px auto;
  max-width: 1000px;
  padding: 5px;
}

.loadingPage {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20% auto;
  max-width: 600px;
  padding: 30px;
}



@media only screen and (max-width: 768px) {
  .searchEngine {
    margin: 10% auto;
    max-width: 90%;
  }

  .searchBar {
    max-width: 100%;
    min-width: 100%;
  }

  .historySelector {
    max-width: 100%;
    min-width: 100%;
  }

  .loadingPage {
    margin: 20% auto;
    max-width: 90%;
  }

  .mainDashboardWrap {
    margin: 40px auto;
    max-width: 90%;
  }
}

@media only screen and (min-width: 769px) {
  .searchEngine {
    margin: 20% auto;
    max-width: 600px;
  }

  .searchBar {
    max-width: 350px;
    min-width: 300px;
  }

  .historySelector {
    max-width: 350px;
    min-width: 300px;
  }

  .loadingPage {
    margin: 20% auto;
    max-width: 600px;
  }

  .mainDashboardWrap {
    margin: 80px auto;
    max-width: 1000px;
  }
}
 */



.formField {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.search-bar {
  display: flex;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.search-bar__prefix {
  font-weight: bold;
  font-size: 20px;
  padding: 10px;
  border-right: 2px solid #ccc;
}

.search-bar__input {
  display: flex;
  text-align: center;
  border: 3px solid rgb(0, 0, 0);

  font-size: 25px;
  padding: 10px;
  text-transform: uppercase;
  background-color: #f8d038;
  font-family: "uknumberplateregular";
  font-weight: bold;
}

.search-bar__submit {
  border: none;
  background-color: #4b45f8;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.search-bar__submit:hover {
  background-color: #1810f6;
}
.loadingPage {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20% auto;
  max-width: 600px;
  padding: 30px;
}
.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alertP {
  color: red;
  margin-top: 5px;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .formField {
    height: auto;
  }
  
  .search-bar {
    flex-direction: column;
    padding: 20px;
  }
  
  .search-bar__prefix {
    border-right: none;
    margin-bottom: 10px;
  }
  
  .search-bar__input {
    margin-bottom: 10px;
  }
  
  .search-bar__submit {
    border-radius: 5px;
  }
}

